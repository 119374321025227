import React from "react"
import axios from "axios"
import ReactLoading from "react-loading";
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

function Hero(props){
  return(
    <section className="w-full mx-auto bg-gray-700 flex h-auto rounded-lg shadow-lg">
      <div className="container mx-auto my-auto">
        <div className="flex flex-row px-10 py-8 text-white">
          <div className="w-full md:w-1/2 pr-8">
            <h1 className="text-6xl tracking-wide font-bold mb-6">Koelinks</h1>
            <p className="text-xl leading-relaxed">
              <ul className="mb-6">
                <li className="mb-2">Improve your blog interlinking quickly and simply.</li>
                <li>No plugins required.</li>
              </ul>
            </p>
          </div>
          <div className="w-full md:w-1/2">
            <div className="md:mx-48">
              <StaticImage
                src="../images/gatsby-icon.png"
                width={250}
                alt="A hand-drawn koel"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function About(props){
  return(
    <section className="w-full mx-auto bg-gray-700 flex h-auto rounded-lg shadow-lg">
      <div className="container mx-auto my-auto">
        <div className="flex flex-col w-full px-10 py-8 text-white">
          <h2 className="text-4xl font-bold tracking-wider mb-2">About</h2>
          <p className="text-xl leading-relaxed">
            Born out of frustration with expensive and intrusive tools and plugins for analysing a Wordpress site,
            Koelinks offers a quick and simple way to figure out which posts need to have their interlinking improved.
            I originally wrote this tool to analyse my own site to improve my own interlinking, then I figured
            why not share it with the world.
          </p>
        </div>
      </div>
    </section>
  )
}

function FAQ(props){
  return(
    <section className="w-full mx-auto bg-gray-700 flex h-auto rounded-lg shadow-lg">
      <div className="container mx-auto my-auto">
        <div className="flex flex-col w-full px-10 py-8 text-white">
          <h2 className="text-4xl font-bold tracking-wider">FAQ</h2>
          <DefinitionList>
            <DefinitionTopic>
              How to Improve Post Interlinking?
            </DefinitionTopic>
            <DefinitionDefinition>
              To improve post interlinking, look at posts without many <span className="italic">inbound</span> links
              from your site, and add links to these posts from other posts. A good
              opportunity for this is from posts which do not have many <span className="italic">outbound</span> links.
              Koelinks Link Analyser can help you figure out which posts need more inbound links
              and which posts need more outbound links. Check it out here: <Link to="/links">Link Analyser</Link>
            </DefinitionDefinition>
            <DefinitionTopic>
              How do I use Koelinks?
            </DefinitionTopic>
            <DefinitionDefinition>
              <ol className="list-decimal ml-6">
                <li>Open up the <Link to='/links'>Link Analyser</Link></li>
                <li>Enter the domain of a wordpress site (please don't link to a post)</li>
                <li>Click GO to identify posts which could benefit from more links (either inbound or outbound)</li>
              </ol>
              <p>The number of posts box indicates the number of posts to analyse. There is currently a 100 post limit on the number of posts to prevent website spam</p>
            </DefinitionDefinition>
            <DefinitionTopic>
              What is Post Interlinking?
            </DefinitionTopic>
            <DefinitionDefinition>
              Post Interlinking is the placing of links in a blog post that guide the reader to open up another blog post
              on your site. Interlinking between posts encourages the reader to stay on your site.
            </DefinitionDefinition>
            <DefinitionTopic>
              Can non-Wordpress sites benefit from interlinking?
            </DefinitionTopic>
            <DefinitionDefinition>
              Any blog can benefit from interlinking, however Koelinks currently only works with Wordpress.
            </DefinitionDefinition>
            <DefinitionTopic>
              Do I need to install a plugin?
            </DefinitionTopic>
            <DefinitionDefinition>
              Plugins are not necessary, Koelinks is designed to work from the browser, without any additional plugins.
            </DefinitionDefinition>
            <DefinitionTopic>
              Why is interlinking posts important?
            </DefinitionTopic>
            <DefinitionDefinition>
              It encourages your users to spend more time with your content. This gives you more opportunity to engage with
              your users, to guide them towards useful products, and it improves a bunch of SEO metrics.
              I found <a href="https://crunchify.com/what-is-interlinking-and-why-it-is-one-of-the-best-seo-practice/">this</a> to be a helpful explanation of why interlinking is useful.
            </DefinitionDefinition>
            <DefinitionTopic>
              Why does my link count not change when I update posts?
            </DefinitionTopic>
            <DefinitionDefinition>
              I found that my ad network, using its cloud provider, was caching my site. This meant that when I updated posts,
              Koelinks would use the old version of the site. If recent changes aren't showing up in Koelinks, try clearing
              the cache with your cloud provider / ad network.
            </DefinitionDefinition>
            <DefinitionTopic>
              I have feedback, feature requests, bug reports, or I want to get in contact.
            </DefinitionTopic>
            <DefinitionDefinition>
              The best way to contact me for feature requests is on <a href="https://www.buymeacoffee.com/koelinkschris" target="_blank">Buy Me a Coffee</a>. Otherwise, my email is contact@koelinks (fill in the .com)
            </DefinitionDefinition>
          </DefinitionList>
        </div>
      </div>
    </section>
  )
}

function DefinitionList(props) {
  return (
    <dl className="my-2">
      {props.children}
    </dl>
  )
}

function DefinitionTopic(props) {
  return (
    <dt className="mt-8 font-bold text-2xl">
      {props.children}
    </dt>
  )
}

function DefinitionDefinition(props) {
  return (
    <dd className="mb-8 leading-relaxed text-xl">
      {props.children}
    </dd>
  )
}

function Table(props){
  return (
    <table className="table-auto w-full">
      {props.children}
    </table>
  )
}

function TableHead(props){
  return (
    <thead>
      {props.children}
    </thead>
  )
}

function TableH(props){
  return (
    <th className={props.addClasses + " px-3 py-2 title-font tracking-wider font-medium text-sm bg-gray-200"}>
      {props.children}
    </th>
  )
}

function TableRowHead(props) {
  return (
    <tr>
      {props.children}
    </tr>
  )
}

function TableBody(props) {
  return (
    <tbody>
      {props.children}
    </tbody>
  )
}

function TableRow(props) {
  return (
    <tr>
      {props.children}
    </tr>
  )
}

function TableData(props) {
  return (
    <td className={props.addClasses + " px-2 py-3 border-t-2 border-gray-200"}>
      {props.children}
    </td>
  )
}

class NumberSelector extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);

  }

  handleChange(e) {
    this.props.handleChange(e.target.value)
  }

  render() {
    return (
      <input
        type="number"
        id="site-number"
        name={this.props.name}
        value={this.props.value}
        onChange={this.handleChange}
        className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-transparent focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
      />
    )
  }
}

class NameSelector extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.props.handleChange(e.target.value);
  }

  render() {
    return (
      <input
        type="text"
        id="site-name"
        name={this.props.name}
        value={this.props.value}
        onChange={this.handleChange}
        className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-transparent focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
      />
    )
  }
}

class LinkAnalyser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      domain: 'anybodysfan.com',
      oldDomain: '',
      domainLength: 0,
      numPosts: 5,
      rawPosts: [],
      posts: {},
      isLoading: false,
      isButtonDisabled: false,
      hasNoData: true,
      error: ''
    }

    this.handleDomainChange = this.handleDomainChange.bind(this);
    this.handleNumPostsChange = this.handleNumPostsChange.bind(this);
    this.handleGo = this.handleGo.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleDomainChange(v) {
    this.setState({domain: v.replace('https://','')});
  }

  handleNumPostsChange(v) {
    this.setState({numPosts: v})
  }

  handleSubmit(e){
    e.preventDefault();
    this.handleGo();
  }

  handleGo() {
    let query = new URL(`https://${this.state.domain}/wp-json/wp/v2/posts?per_page=${this.state.numPosts}`)
    let headers = {}
    let dLength = this.state.domain.length
    this.setState({ isLoading: true })
    this.setState({isButtonDisabled: true})
    this.setState({domainLength: dLength})
    this.setState({oldDomain: this.state.domain})
    axios.get(query, headers)
      .then(res => {
        const rawPosts = res.data;
        this.setState( {rawPosts} );
        this.setState( {error: ''} )
        setTimeout(() => this.setState({ isButtonDisabled: false }), 1000+Math.floor(Math.random()*1000));
      })
      .catch(error => {
        this.setState({ isButtonDisabled: false })
        this.setState( {error: error.message} )
      })
      .finally( () => {
        this.setState({ isLoading: false })
      })
  }

  render() {
    
    if(this.state.hasNoData){
      this.handleGo();
      this.setState({hasNoData: false})
    }
    
    console.log(this.state.domainLength);
    let reHref = new RegExp(/href=\".*?\"/,"g")
    let posts = {}
    for(const post of this.state.rawPosts){
      posts[post.link] = (posts[post.link] || {});
      posts[post.link]['internal'] = new Array(0);
      posts[post.link]['external'] = new Array(0);
      posts[post.link]['outboundURLs'] = new Array(0);
      if(post.content.rendered.toString().match(reHref)){
        for(const u of post.content.rendered.toString().match(reHref)){
          let ref='/';
          try {
            ref = new URL(u.substring(6, u.length-1).replace('www.',''))
            ref.protocol = 'https'
          } catch (error) {
            // add malformed links
          }
          posts[post.link].outboundURLs.push(ref);
        }
      } else {
        // posts with no links
      }
    }

    let inboundLinks = {}
    for(const post of Object.keys(posts)) {
      inboundLinks[post] = (inboundLinks[post] || {})
      inboundLinks[post].count = (inboundLinks[post].count || 0);

      for(const link of posts[post].outboundURLs){
        if(link.hostname === this.state.oldDomain){
          inboundLinks[link.href] = (inboundLinks[link.href] || {})
          inboundLinks[link.href].count = (inboundLinks[link.href].count || 0) + 1
        }
      }
    }

    let outboundLinksInternal = {}
    let outboundLinksExternal = {}
    for(const post of Object.keys(posts)){
      outboundLinksInternal[post] = (outboundLinksInternal[post] || {})
      outboundLinksInternal[post].count = (outboundLinksInternal[post].count || 0)
      outboundLinksExternal[post] = (outboundLinksExternal[post] || {})
      outboundLinksExternal[post].count = (outboundLinksExternal[post].count || 0)
      for(const link of posts[post].outboundURLs){
        if(link.hostname === this.state.oldDomain){
          outboundLinksInternal[post].count = (outboundLinksInternal[post].count || 0) + 1
        } else {
          outboundLinksExternal[post].count = (outboundLinksExternal[post].count || 0) + 1
        }
      }
    }

    return(
      <div>
        <div className="w-full h-auto justify-center items-center bg-white shadow-lg rounded-lg flex flex-col my-2">
          <form
            onSubmit={this.handleSubmit}
            className="flex lg:w-2/3 my-auto w-full sm:flex-row flex-col mx-auto px-8 py-8 sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 items-end">
            <div className="relative flex-grow w-full">
              <label htmlFor="site-name" className="leading-7 text-sm text-gray-600">Domain</label>
              <NameSelector
                name="site-name"
                value={this.state.domain}
                handleChange={this.handleDomainChange}
              />
            </div>
            <div className="relative flex-grow w-full">
              <label htmlFor="site-number" className="leading-7 text-sm text-gray-600">Number of posts</label>
              <NumberSelector
                name="site-number"
                value={this.state.numPosts}
                handleChange={this.handleNumPostsChange}
              />
            </div>
            <button
              className={
                this.state.isButtonDisabled ? (
                  "text-white bg-gray-500 border-0 py-2 px-8 focus:outline-none hover:bg-gray-600 rounded text-lg"
                ) : (
                  "text-white bg-blue-500 border-0 py-2 px-8 focus:outline-none hover:bg-blue-600 rounded text-lg"
                )}
              onClick={this.handleGo}
              disabled={this.state.isButtonDisabled}
            >
                Go
            </button>
            <div>
              
              {this.state.isButtonDisabled || this.state.isLoading ? (
                  <ReactLoading type="bubbles" color="000000" />
                ) : (
                  <span></span>
                )
              }
            </div>
          </form>
        </div>

        {
        this.state.error ? (
          <div className="w-full h-auto justify-center items-center bg-white shadow-lg rounded-lg flex flex-col my-2">
            <div className="flex lg:w-2/3 w-full sm:flex-row flex-col mx-auto px-8 py-8 sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 items-end">
              <p>{this.state.error}</p>
              <p>Couldn't read data from domain</p>
            </div>
          </div>
        ) : (<div></div>)
        }

        <div className="w-full h-auto justify-center bg-white shadow-lg rounded-lg flex flex-row my-2">
          <div className="h-full md:w-5/12 w-full flex-1 bg-gray-100 bg-opacity-75 px-8 pt-4 pb-4 my-4 ml-4 mr-2 rounded-lg overflow-hidden text-center relative">
            <h2 class="tracking-widest text-xs title-font font-medium text-gray-400 mb-2 px-2">Links INBOUND per post</h2>
            <Table>
              <TableHead>
                <TableRowHead>
                  <TableH addClasses="rounded-tl rounded-bl">Post</TableH>
                  <TableH addClasses="rounded-tr rounded-br">Count</TableH>
                </TableRowHead>
              </TableHead>
              <TableBody>
                {
                  Object.keys(inboundLinks).sort(
                    function(a,b) {return (inboundLinks[a].count || 0) - (inboundLinks[b].count || 0)}
                  ).map(post =>
                    <TableRow>
                      <TableData addClasses="text-left">
                        <a href={post}>{post.replace('https://','').replace(this.state.oldDomain+'/','')}</a>
                      </TableData>
                      <TableData>
                        {inboundLinks[post].count || 0}
                      </TableData>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </div>
          <div className="h-full md:w-5/12 w-full flex-1 bg-gray-100 bg-opacity-75 px-8 pt-4 pb-4 my-4 ml-2 mr-4 rounded-lg overflow-hidden text-center relative">
            <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-2 px-2">Links OUTBOUND per post (internal)</h2>
            <Table>
              <TableHead>
                <TableRowHead>
                  <TableH addClasses="rounded-tl rounded-bl">Post</TableH>
                  <TableH addClasses="rounded-tr rounded-br">Count</TableH>
                </TableRowHead>
              </TableHead>
              <TableBody>
                {
                  Object.keys(outboundLinksInternal).sort(
                    function(a,b) {return (outboundLinksInternal[a].count || 0) - (outboundLinksInternal[b].count || 0)}
                  ).map(post =>
                    <TableRow>
                      <TableData addClasses="text-left">
                        <a href={post}>{post.replace('https://','').substring(this.state.domainLength+1, post.length-1)}</a>
                      </TableData>
                      <TableData>
                        {outboundLinksInternal[post].count || 0}
                      </TableData>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </div>
        </div>
        </div>
    )
  }
}

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero />
    <div className="h-8"></div>
    <FAQ />
    <div className="h-8"></div>
    <About />
  </Layout>
)

/*
const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <StaticImage
        src="../images/gatsby-astronaut.png"
        alt="gatsby astronaut"
      />
    </div>
    <p><Link to="/page-2/">Go to page 2</Link></p>
    <p><Link to="/using-typescript/">Go to "Using TypeScript"</Link></p>
  </Layout>
)
*/

export default IndexPage
